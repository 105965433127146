import React, { forwardRef } from "react";
import "../styles/footer/footer.css";
import { useMediaQuery } from "@mui/material";
import ProfilePicture from "../assets/images/avvocato_profilo.png";

import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

function Footer(props, ref) {
  const is1400Screen = useMediaQuery("(max-width:1400px)");
  const is1200Screen = useMediaQuery("(max-width:1200px)");
  const is1000Screen = useMediaQuery("(max-width:1000px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <div className="footer-container">
      <div className="footer-background" ref={ref}>
        <div className="left-side-footer">
          <h1>Studio Legale Avv. Lancetti</h1>
          <div className="title-line"></div>
          {is1000Screen && (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11079.08537942864!2d9.1244947!3d46.0357108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47843af8881570b5%3A0x6b7300d8324aefc7!2sStudio%20Legale%20Associato%20Avvocati%20Lancetti%26Mainoni%20Porlezza!5e0!3m2!1sit!2sit!4v1692998376855!5m2!1sit!2sit"
              width="400"
              height="300"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          )}
          <div className="footer-text">
            <p>
              <AccessTimeFilledIcon className="material-footer-icons md-36" />
              {!isSmallScreen && (
                <>
                  Orari di apertura: Lun-Sab alle ore 9:00-12:30 e 15:00-19:00
                </>
              )}
              {isSmallScreen && <>Orari: Lun-Sab 9:00-12:30 e 15:00-19:00</>}
            </p>{" "}
            <a href="https://goo.gl/maps/hhvnmPPjvCDQU5dw9" target="_blank">
              <p>
                <RoomIcon className="material-footer-icons md-36" />
                {!isSmallScreen && (
                  <>Indirizzo: Via Ghiacciaia, 10/A, 22018 Porlezza CO</>
                )}
                {isSmallScreen && <>Via Ghiacciaia, 10/A, 22018 Porlezza CO</>}
              </p>{" "}
            </a>
            <p>
              {" "}
              <PhoneIcon className="material-footer-icons md-36" />
              Telefono: +39 034472438
            </p>
            <p>
              {" "}
              <EmailIcon className="material-footer-icons md-36" />
              Email: avv.ernestina.lancetti@gmail.com
            </p>
          </div>
        </div>
        {!is1000Screen && (
          <div
            className="right-side-footer"
            href="https://goo.gl/maps/hhvnmPPjvCDQU5dw9"
          >
            {(is1200Screen && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11079.08537942864!2d9.1244947!3d46.0357108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47843af8881570b5%3A0x6b7300d8324aefc7!2sStudio%20Legale%20Associato%20Avvocati%20Lancetti%26Mainoni%20Porlezza!5e0!3m2!1sit!2sit!4v1692998376855!5m2!1sit!2sit"
                width="600"
                height="300"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            )) ||
              (is1400Screen && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11079.08537942864!2d9.1244947!3d46.0357108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47843af8881570b5%3A0x6b7300d8324aefc7!2sStudio%20Legale%20Associato%20Avvocati%20Lancetti%26Mainoni%20Porlezza!5e0!3m2!1sit!2sit!4v1692998376855!5m2!1sit!2sit"
                  width="600"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              )) ||
              (!is1000Screen && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11079.08537942864!2d9.1244947!3d46.0357108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47843af8881570b5%3A0x6b7300d8324aefc7!2sStudio%20Legale%20Associato%20Avvocati%20Lancetti%26Mainoni%20Porlezza!5e0!3m2!1sit!2sit!4v1692998376855!5m2!1sit!2sit"
                  width="700"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
export default forwardRef(Footer);
