import React, { forwardRef } from "react";
import { useMediaQuery } from "@mui/material";

import Logo from "../assets/images/logo_studio.png";
import LogoBianco from "../assets/images/logo_studio_bianco.png";
import DrawerHeader from "../components/ui/drawer";
import ExpandableCard from "../components/funcComponents/expandableCard/ExpandableCard";

import "../styles/homePage/homePage.css";

function HomePage(
  {
    onAboutButtonClick,
    onHomeButtonClick,
    onGalleryButtonClick,
    onCurriculumButtonClick,
    onSpecializationButtonClick,
    onContactsButtonClick,
  },
  ref
) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <div ref={ref} className="backgroundPicture">
      {isSmallScreen && (
        <DrawerHeader
          aboutButtonClick={onAboutButtonClick}
          homeButtonClick={onHomeButtonClick}
          galleryButtonClick={onGalleryButtonClick}
          curriculumButtonClick={onCurriculumButtonClick}
          specializationButtonClick={onSpecializationButtonClick}
          contactsButtonClick={onContactsButtonClick}
        />
      )}
      {!isSmallScreen && (
        <div className="header">
          <img className="logo-img" src={Logo} />
          {/* <div className="logo-text">
            <h2 style={{ marginBottom: "-1px", marginTop: "-3px" }}>
              STUDIO LEGALE
            </h2>
            <span>Avv. Ernestina Lancetti</span>
          </div> */}
          <div className="headerMenu">
            <a onClick={onAboutButtonClick}>Chi siamo</a>
            <a onClick={onCurriculumButtonClick}>L'avvocato</a>
            <a onClick={onSpecializationButtonClick}>Materie trattate</a>
            <a onClick={onGalleryButtonClick}>Galleria</a>
            <a onClick={onContactsButtonClick}>Contatti</a>
          </div>
        </div>
      )}
      {/* <ExpandableCard /> */}
      <div className="title-container">
        <img className="logo-img-white" src={LogoBianco} />
        <div className="logo-text">
          <h1 className="main-title" style={{ marginBottom: "-20px" }}>
            STUDIO LEGALE
          </h1>
          <h1 className="main-title">Avv. Ernestina Lancetti</h1>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(HomePage);
