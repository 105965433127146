import React, { forwardRef, useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "../styles/specializations/specializations.css";

import ContactUs from "../components/funcComponents/contactForm/contactForm";

function Contacts(props, ref) {
  const [state, setState] = useState({
    topic: "Penale",
  });

  function changeTopic(e) {
    console.log("e.target.textContent", e.target.textContent);
    setState({
      ...state,
      topic: e.target.textContent,
    });
  }

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  /*useEffect(() => {
    
  }, []);*/

  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `url(${require("../assets/images/bottom_background.png")})`,
        backgroundSize: "cover", // You might also want to set other background properties
      }}
    >
      <div className="specializations">
        <div className="introduction-card" style={{ width: "80%" }}>
          <h1>Consulenza Online</h1>

          <div className="curriculum-text" style={{ width: "80%" }}>
            <div className="curriculum-text-internal">
              <p>
                Anche la consulenza legale, a seguito dell’emergenza sanitaria
                da Covid- 19, ha dovuto adeguarsi e digitalizzarsi. Per tale
                motivo{" "}
                <b>
                  l’avv. Lancetti mette a disposizione dei propri clienti un
                  servizio di assistenza legale on line.
                </b>
              </p>{" "}
              <p>
                Gli appuntamenti, infatti, oltre che in modo tradizionale ed in
                presenza presso lo studio dell’avvocato, potranno svolgersi,
                previa prenotazione tramite email o telefonica, in modalità
                telematica tramite una consulenza da remoto da qualsiasi luogo
                in cui ci si trovi. L’avvocato conferirà con il cliente in una
                chiamata o videochiamata durante la quale nel rispetto della
                privacy, della segretezza e sempre con estrema professionalità,
                ascolterà ed esprimerà un primo succinto parere sulla
                problematica legale sottopostagli.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="specializations">
        <div
          className="introduction-card"
          style={{ width: "80%", marginBottom: "50px" }}
        >
          <h1>Contattaci</h1>
          <p className="title-line"></p>

          {/* <div className="curriculum-text" style={{ width: "80%" }}>
            <div className="curriculum-text-internal">
              <p>
                Anche la consulenza legale, a seguito dell’emergenza sanitaria
                da Covid- 19, ha dovuto adeguarsi e digitalizzarsi. Per tale
                motivo{" "}
                <b>
                  l’avv. Lancetti mette a disposizione dei propri clienti un
                  servizio di assistenza legale on line.
                </b>
              </p>{" "}
              <p>
                Gli appuntamenti, infatti, oltre che in modo tradizionale ed in
                presenza presso lo studio dell’avvocato, potranno svolgersi,
                previa prenotazione tramite email o telefonica, in modalità
                telematica tramite una consulenza da remoto da qualsiasi luogo
                in cui ci si trovi. L’avvocato conferirà con il cliente in una
                chiamata o videochiamata durante la quale nel rispetto della
                privacy, della segretezza e sempre con estrema professionalità,
                ascolterà ed esprimerà un primo succinto parere sulla
                problematica legale sottopostagli.
              </p>
            </div>
          </div> */}
          <ContactUs />
        </div>
      </div>
    </div>
  );
}
export default forwardRef(Contacts);
