import React, { useRef } from "react";
import ReactDOM from "react-dom/client";
import "./styles/common.css";

// SCREENS
import About from "./screens/About";
import Contacts from "./screens/Contacts";
import Curriculum from "./screens/Curriculum";
import Gallery from "./screens/Gallery";
import HomePage from "./screens/HomePage";
import Specializations from "./screens/Specializations";
import Footer from "./screens/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const aboutRef = useRef(null);
  const homePageRef = useRef(null);
  const specializationsRef = useRef(null);
  const galleryRef = useRef(null);
  const contactsRef = useRef(null);
  const curriculumRef = useRef(null);

  const handleAboutPageButtonClick = () => {
    aboutRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  const handleHomePageButtonClick = () => {
    homePageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  const handleSpecializationsButtonClick = () => {
    specializationsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  const handleGalleryButtonClick = () => {
    galleryRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  const handleCurriculumButtonClick = () => {
    curriculumRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  const handleContactsButtonClick = () => {
    contactsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the start of the target element
      inline: "nearest", // Align to the nearest edge
    });
  };

  return (
    <React.StrictMode>
      <HomePage
        onAboutButtonClick={handleAboutPageButtonClick}
        onHomeButtonClick={handleHomePageButtonClick}
        onCurriculumButtonClick={handleCurriculumButtonClick}
        onSpecializationButtonClick={handleSpecializationsButtonClick}
        onGalleryButtonClick={handleGalleryButtonClick}
        onContactsButtonClick={handleContactsButtonClick}
        ref={homePageRef}
      />
      <About ref={aboutRef} />
      <Curriculum ref={curriculumRef} />
      <Specializations ref={specializationsRef} />
      <Gallery ref={galleryRef} />
      <Contacts ref={contactsRef} />
      <Footer />
    </React.StrictMode>
  );
}

root.render(<App />);
