import image1 from "../../../assets/images/image1.jpeg";
import image2 from "../../../assets/images/image2.jpeg";
import image3 from "../../../assets/images/image3.jpeg";
import image4 from "../../../assets/images/image4.jpeg";
import image5 from "../../../assets/images/image5.jpeg";
import image6 from "../../../assets/images/image6.jpeg";
import image7 from "../../../assets/images/image7.jpeg";
import image8 from "../../../assets/images/image8.jpeg";
import image9 from "../../../assets/images/image9.jpeg";
import IconMockup from "../../../assets/images/icon_mockup.jpg";

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./imagesGallery.css";

const ImageGallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCss: true,
  };

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    /*"https://picsum.photos/id/1015/800/600",
    "https://picsum.photos/id/1016/800/600",
    "https://picsum.photos/id/1018/800/600",
    "https://picsum.photos/id/1020/800/600",*/
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageGallery;
