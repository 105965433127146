import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FeedIcon from "@mui/icons-material/Feed";
import MailIcon from "@mui/icons-material/Mail";
import PanoramaIcon from "@mui/icons-material/Panorama";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

import Logo from "../../assets/images/logo_studio.png";

const BlackMenuIcon = styled(MenuIcon)({
  color: "black",
});
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight({
  aboutButtonClick,
  homeButtonClick,
  galleryButtonClick,
  curriculumButtonClick,
  specializationButtonClick,
  contactsButtonClick,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleHomeButtonClick() {
    homeButtonClick();
    setOpen(false);
  }

  function handleAboutButtonClick() {
    aboutButtonClick();
    setOpen(false);
  }

  function handleGalleryButtonClick() {
    galleryButtonClick();
    setOpen(false);
  }

  function handleCurriculumButtonClick() {
    curriculumButtonClick();
    setOpen(false);
  }

  function handleSpecializationButtonClick() {
    specializationButtonClick();
    setOpen(false);
  }

  function handleContactsButtonClick() {
    contactsButtonClick();
    setOpen(false);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "#F7F4F3", opacity: "80%" }}
      >
        <Toolbar style={{ maxWidth: "100vw" }}>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              maxHeight: { xs: 50, md: 30 },
              maxWidth: { xs: 50, md: 30 },
            }}
            alt="SUDIO LEGALE Avv. Lancetti Ernestina"
            src={Logo}
          />
          <Typography
            noWrap
            sx={{
              flexGrow: 1,
              color: "black",
              fontFamily: "Perpetua Titling MT Light, serif",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
            component="div"
          >
            Studio Legale Lancetti
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <BlackMenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton onClick={handleHomeButtonClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>

          <ListItemButton onClick={handleAboutButtonClick}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Chi siamo"} />
          </ListItemButton>

          <ListItemButton onClick={handleCurriculumButtonClick}>
            <ListItemIcon>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText primary={"Curriculum"} />
          </ListItemButton>

          <ListItemButton onClick={handleSpecializationButtonClick}>
            <ListItemIcon>
              <AssuredWorkloadIcon />
            </ListItemIcon>
            <ListItemText primary={"Materie trattate"} />
          </ListItemButton>

          <ListItemButton onClick={handleGalleryButtonClick}>
            <ListItemIcon>
              <PanoramaIcon />
            </ListItemIcon>
            <ListItemText primary={"Galleria"} />
          </ListItemButton>

          <ListItemButton onClick={handleContactsButtonClick}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={"Contatti"} />
          </ListItemButton>
        </List>
        <Divider />
        {/*
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        */}
      </Drawer>
    </Box>
  );
}
