import React, { forwardRef, useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "../styles/specializations/specializations.css";

//Icons
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import FamilyRestroomRoundedIcon from "@mui/icons-material/FamilyRestroomRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import TimeToLeaveRoundedIcon from "@mui/icons-material/TimeToLeaveRounded";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TrafficIcon from "@mui/icons-material/Traffic";
import CottageIcon from "@mui/icons-material/Cottage";
import MedicationIcon from "@mui/icons-material/Medication";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Person4Icon from "@mui/icons-material/Person4";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FoundationIcon from "@mui/icons-material/Foundation";
import ForestIcon from "@mui/icons-material/Forest";

function Specializations(props, ref) {
  const [state, setState] = useState({
    topic: "Penale",
  });

  function changeTopic(e) {
    console.log("e.target.textContent", e.target.textContent);
    setState({
      ...state,
      topic: e.target.textContent,
    });
  }

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  /*useEffect(() => {
    
  }, []);*/

  return (
    <div className="background-picture" ref={ref}>
      <div className="specializations">
        <div className="introduction-card">
          <h1>Materie trattate</h1>

          <div className="specializations-text">
            <p>
              L’attività dello studio si articola in tre campi principali:{" "}
              {"     "}
              <b> Diritto Civile</b>, <b>Diritto penale</b> e{" "}
              <b>Diritto Amministrativo</b>
            </p>{" "}
          </div>
        </div>

        <div className="introduction-card">
          <Tabs
            value={state.topic}
            onChange={changeTopic}
            className="topic-tab"
          >
            <Tab label="Civile" value="Civile" style={{ fontWeight: "bold" }} />
            <Tab label="Penale" value="Penale" style={{ fontWeight: "bold" }} />
            <Tab
              label="Amministrativo"
              value="Amministrativo"
              style={{ fontWeight: "bold" }}
            />
          </Tabs>
          <div className="body-tab">
            {state.topic === "Penale" && (
              <>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <AttachMoneyRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati contro il patrimonio
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <MapsHomeWorkRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati di edilizia e urbanistica
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <AccountBalanceRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati contro la Pubblica Amministrazione
                  </span>
                </div>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <ComputerRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati informatici
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <FamilyRestroomRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati contro la famiglia
                  </span>
                </div>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <Person2RoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati contro la persona
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <TimeToLeaveRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Reati del Codice della Strada
                  </span>
                </div>
              </>
            )}
            {state.topic === "Civile" && (
              <>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <AssignmentIcon className="material-icons md-36" />
                      </div>
                    )}
                    Assicurazioni
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <HistoryEduIcon className="material-icons md-36" />
                      </div>
                    )}
                    Contrattualistica
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <TrafficIcon className="material-icons md-36" />
                      </div>
                    )}
                    Controversie in materia di codice della strada
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <LocationCityIcon className="material-icons md-36" />
                      </div>
                    )}
                    Controversie in materia di locazioni e condominio
                  </span>
                </div>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <FamilyRestroomRoundedIcon className="material-icons md-36" />
                      </div>
                    )}
                    Diritto di famiglia
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <CottageIcon className="material-icons md-36" />
                      </div>
                    )}
                    Proprietà e diritti reali, immobiliari
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <MedicationIcon className="material-icons md-36" />
                      </div>
                    )}
                    Responsabilità medica
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <WorkspacePremiumIcon className="material-icons md-36" />
                      </div>
                    )}
                    Recupero crediti giudiziali e stragiudiziali
                  </span>
                </div>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <Person4Icon className="material-icons md-36" />
                      </div>
                    )}
                    Tutela del consumatore
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <LocalAtmIcon className="material-icons md-36" />
                      </div>
                    )}
                    Successioni e donazioni
                  </span>
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <Diversity3Icon className="material-icons md-36" />
                      </div>
                    )}
                    Risarcimento danni e responsabilità civile
                  </span>
                </div>
              </>
            )}
            {state.topic === "Amministrativo" && (
              <>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <FoundationIcon className="material-icons md-36" />
                      </div>
                    )}
                    Diritto dell’urbanistica e dell’edilizia
                  </span>
                </div>
                <div className="element-body-tab">
                  <span>
                    {!isSmallScreen && (
                      <div className="icon-container">
                        <ForestIcon className="material-icons md-36" />
                      </div>
                    )}
                    Diritto dell’ambiente
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default forwardRef(Specializations);
