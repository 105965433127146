import React, { forwardRef } from "react";
import "../styles/curriculum/curriculum.css";
import { useMediaQuery } from "@mui/material";
import ProfilePicture from "../assets/images/avvocato_profilo.jpg";

function Curriculum(props, ref) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <div className="curriculum-background" ref={ref}>
      <div className="left-side">
        <h1>CURRICULUM VITAE</h1>
        {isSmallScreen && (
          <img src={ProfilePicture} className="profile-picture"></img>
        )}
        <div className="curriculum-text">
          <p>
            <b>L’avv. Ernestina Lancetti</b> è nata a Gravedona (Co) il
            05.11.1967. Dopo aver conseguito la maturità presso l’Istituto Ezio
            Vanoni di Menaggio, si é iscritta alla facoltà di Giurisprudenza
            presso l’Università degli Studi di Milano e nel contempo ha
            collaborato per due anni come giornalista /pubblicista presso due
            testate giornalistiche.
          </p>{" "}
          <p>
            Si è{" "}
            <b>
              laureata nel 1992 con una tesi in diritto civile in materia
              possessoria.
            </b>{" "}
            Esercita in maniera esclusiva l’attività forense dal 1994, quando ha
            aperto il proprio studio da praticante abilitata.
          </p>
          <p>
            Nel 1997 ha conseguito il titolo di avvocato (l’abilitazione
            forense) tramite esame sostenuto presso la Corte d’Appello di
            Milano.
          </p>
          <p>
            {" "}
            E’ iscritta all’Albo Speciale dei patrocinatori avanti la{" "}
            <b>Corte di Cassazione</b> e le <b>Corti Superiori</b> dal 2010.{" "}
          </p>
        </div>
      </div>
      <div className="right-side">
        {!isSmallScreen && (
          <img src={ProfilePicture} className="profile-picture"></img>
        )}
      </div>
    </div>
  );
}
export default forwardRef(Curriculum);
