import React, { forwardRef } from "react";
import "../styles/gallery/gallery.css";
import ImagesGallery from "../components/funcComponents/imagesGallery/ImagesGallery";

function Gallery(props, ref) {
  return (
    <div className="background" ref={ref}>
      <div className="gallery-container">
        <h1>Galleria</h1>
        <div
          style={{
            width: "60%",
            marginBottom: "50px",
          }}
        >
          <ImagesGallery />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Gallery);
