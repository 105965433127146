import React from "react";
import MyButton from "@mui/material/Button";
import "./button.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a28778",
    },
  },
});

function CustomButton(text, action) {
  return (
    <div className="button-container">
      <ThemeProvider theme={theme}>
        <MyButton variant="contained" color="primary" onClick={action}>
          {text}
        </MyButton>
      </ThemeProvider>
    </div>
  );
}
export { CustomButton };
